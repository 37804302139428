<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row>
                <b-col md="4" v-if="userType=='admin'">
                    <base-input :label="$t('general.patient')">
                        <el-select v-model="filter.patient" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in patientList" :key="key" :label="item.name+' - '+(item.phone?item.phone:'0000')" :value="item.uuid"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="3">
                    <v-date-picker v-model="range" :locale="userLang" mode="dates" :masks="masks" is-range @input="setDates">
                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <b-row>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                    <input class="form-control"  :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                </b-col>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                    <input class="form-control"  :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                </b-col>
                            </b-row>
                        </template>
                    </v-date-picker>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.currency')">
                        <el-select v-model="filter.currency" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in currencyList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.payment_status')">
                        <el-select v-model="filter.paymentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <div class="text-right my-3" v-if="userType=='admin'">
            <base-button type="success" @click="openPaymentModal(false)"><i class="fas fa-plus"></i> {{$t('general.add')}}</base-button>
        </div>
        <b-card no-body v-if="payments" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="payments" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.payment_id')" prop="uuid" min-width="280">
                    <template v-slot="{row}">
                        <small class="nowrap">{{row.uuid}}</small>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.appointment_id')" prop="uuid" min-width="280">
                    <template v-slot="{row}">
                        <small class="nowrap">{{row.appointment.uuid!=undefined?row.appointment.uuid:''}}</small>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_status')" min-width="180">
                    <template v-slot="{row}">
                        <b-badge :variant="(row.payed?'success':'primary')">
                            <template v-if="row.payed">{{$t('general.completed')}}</template>
                            <template v-else>{{$t('general.waiting')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.doctor')" prop="availability.doctor" min-width="240" v-if="userType!='doctor'">
                    <template v-slot="{row}">
                        {{row.availability.doctor.title}}{{row.availability.doctor.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.branch')" prop="availability.doctor.branch" min-width="240" v-if="userType!='doctor'">
                    <template v-slot="{row}">
                        {{row.availability.doctor.branch|getBranchName}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.patient')" prop="patient" min-width="200">
                    <template v-slot="{row}">
                        {{row.patient.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.appointment')" prop="availability" min-width="280">
                    <template v-slot="{row}">
                        <div v-if="row.availability">
                            <b-badge variant="primary" class="mr-1">{{$t('availability.'+row.availability.type)}}</b-badge>
                            <b-badge variant="primary" class="mr-1">{{row.availability.date | getIntDate}}</b-badge>
                            <b-badge variant="primary" class="mr-1">{{row.availability.time | getIntTime}}</b-badge>
                            <b-badge variant="primary" class="mr-1">{{row.availability.min}} {{$t('general.min')}}</b-badge>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.price')" prop="price" min-width="160">
                    <template v-slot="{row}">
                        {{row.amount}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('payment.add_date')" prop="createdAt" min-width="200">
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.period_validity')" prop="period_validity" min-width="200">
                    <template v-slot="{row}">
                        {{row | getValidityDate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('payment.payed_date')" prop="payed_date" min-width="200">
                    <template v-slot="{row}">
                        <span v-if="row.payed_date!=undefined">{{row.payed_date | getDate}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="200">
                    <template slot-scope="scope">
                        <div v-if="userType=='admin' && !scope.row.payed">
                            <base-button type="primary" size="sm" @click="openPaymentModal(scope.row.uuid)">{{$t('general.edit')}}</base-button>
                            <base-button type="danger" size="sm" @click="handleDelete(scope.row.uuid)">{{$t('general.delete')}}</base-button>
                        </div>
                        <div v-if="userType=='patient' && !scope.row.payed && checkValidityTime(scope.row.createdAt, scope.row.period_validity)">
                            <base-button type="primary" size="sm" @click="openCollectionModal(scope.row)">{{$t('general.pay')}}</base-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="processModal.status" modal-classes="modal-danger" modal-content-classes="bg-gradient-primary" size="sm">
            <h6 slot="header" class="modal-title">{{$t('general.process')}}</h6>

            <div class="py-3 text-center">
                <base-button type="secondary" block size="sm" @click="doProcess('approved')">{{$t('general.approved_refund')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('transfer')">{{$t('general.balance_transfer')}}</base-button>
            </div>

            <template slot="footer">
                <base-button type="link" class="text-white ml-auto" @click="processModal.status = false">{{$t('general.close')}}</base-button>
            </template>
        </modal>

        <modal :show.sync="paymentModal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="closePaymentModal" size="lg">
            <h6 slot="header" class="modal-title">{{$t('general.process')}}</h6>

            <div class="py-3">
                <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                    <b-form role="form" @submit.prevent="handleSubmit(paymentModal.formData.uuid!==undefined?updatePaymentLink:addPaymentLink)" v-loading="paymentModal.loading">
                        <b-row>
                            <b-col md="4">
                                <base-input :label="$t('general.patient')">
                                    <el-select v-model="paymentModal.data.patient" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="changePatientSelect">
                                        <el-option v-for="(item, key) in patientList" :key="key" :label="item.name+' - '+(item.phone?item.phone:'0000')" :value="item.uuid"></el-option>
                                    </el-select>
                                </base-input>
                            </b-col>
                            <b-col md="4">
                                <label class="form-control-label"> {{$t('general.amount')}} </label>
                                <validation-provider :rules="'required|min_value:0.1|max_value:'+(paymentModal.formData.maxAmount)" :name="$t('general.amount')" v-slot="{ errors, valid, invalid, validated }">
                                    <b-input-group>
                                        <template #append>
                                            <b-input-group-text>{{paymentModal.formData.currency | getCurrency}}</b-input-group-text>
                                        </template>
                                        <b-form-input v-model="paymentModal.formData.amount" :disabled="paymentModal.formData.availability==''"></b-form-input>
                                    </b-input-group>
                                    <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                        {{ errors[0] }}
                                    </div>
                                </validation-provider>
                            </b-col>
                            <b-col md="4">
                                <base-input :label="$t('general.period_validity')">
                                    <el-select v-model="paymentModal.formData.period_validity" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')">
                                        <el-option :value="30" :label="`30 ${$t('general.min')}`">30 {{$t('general.min')}}</el-option>
                                        <el-option :value="60" :label="`1 ${$t('general.hour')}`">1 {{$t('general.hour')}}</el-option>
                                        <el-option :value="720" :label="`12 ${$t('general.hour')}`">12 {{$t('general.hour')}}</el-option>
                                        <el-option :value="1440" :label="`1 ${$t('general.day')}`">1 {{$t('general.day')}}</el-option>
                                        <el-option :value="4320" :label="`3 ${$t('general.day')}`">3 {{$t('general.day')}}</el-option>
                                        <el-option :value="7200" :label="`5 ${$t('general.day')}`">5 {{$t('general.day')}}</el-option>
                                        <el-option :value="10080" :label="`7 ${$t('general.day')}`">7 {{$t('general.day')}}</el-option>
                                    </el-select>
                                </base-input>
                            </b-col>
                            <b-col md="12" v-if="appointments && appointments.length>0">
                                <label for="">{{$t('general.appointment')}}</label>
                                <table class="table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{{$t('general.doctor')}}</th>
                                            <th>{{$t('general.inspection_type')}}</th>
                                            <th class="text-center">{{$t('appointment.date')}}</th>
                                            <th class="text-center">{{$t('appointment.time')}}</th>
                                            <th class="text-center">{{$t('appointment.delay')}}</th>
                                            <th class="text-right">{{$t('general.amount')}}</th>
                                            <th class="text-right">{{$t('general.remaining')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, key) in appointments" :key="key">
                                            <td>
                                                <b-form-radio-group v-model="paymentModal.formData.availability">
                                                    <b-form-radio :value="item.availability[0]._id" name="radio-size" size="sm" @change="changeAppointmentSelect(item)"/>
                                                </b-form-radio-group>
                                            </td>
                                            <td width="150">{{item.doctor[0].title}}{{item.doctor[0].name}}</td>
                                            <td width="140">{{$t('availability.'+item.availability[0].type)}}</td>
                                            <td width="120" class="text-center">{{item.availability[0].date | getIntDate}}</td>
                                            <td width="70" class="text-center">{{item.availability[0].time | getIntTime}}</td>
                                            <td width="70" class="text-center">{{item.availability[0].min}} {{$t('general.min')}}</td>
                                            <td width="70" class="text-right">{{item.availability[0].price}} {{item.availability[0].currency | getCurrency}}</td>
                                            <td width="70" class="text-right">{{item | getRemaining}} {{item.availability[0].currency | getCurrency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>
                        <hr>
                        <base-button type="success" size="sm" native-type="submit" class="float-right" :disabled="paymentModal.loading || invalid">{{$t('general.save')}}</base-button>
                        <b-button size="sm" variant="danger" @click="closePaymentModal"> {{ $t('general.cancel') }} </b-button>
                    </b-form>
                </validation-observer>
            </div>
        </modal>

        <modal :show.sync="collectionModal.status" :backdropClose="false" :hideFooter="true" @close="closeCollectionModal">
            <h6 slot="header" class="modal-title">Ödeme Linki Tahsilatı</h6>
            <div v-if="collectionModal.startPayment" id="payment-modal">
                <iframe :src="collectionModal.transactionSrc" frameborder="0" v-if="collectionModal.transactionSrc" />
                <b-button block squared variant="danger" class="mt-3" @click="closeCollectionModal">{{$t('general.close')}}</b-button>
            </div>
            <div v-else>
                <table class="table table-bordered" v-if="collectionModal.data.uuid">
                    <tbody>
                        <tr>
                            <td><strong>{{$t('general.payment_id')}}</strong></td>
                            <td>{{collectionModal.data.uuid}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{$t('general.doctor')}}</strong></td>
                            <td>{{collectionModal.data.availability.doctor.title}}{{collectionModal.data.availability.doctor.name}} / {{collectionModal.data.availability.doctor.branch|getBranchName}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{$t('general.appointment')}}</strong></td>
                            <td>
                                <div v-if="collectionModal.data.availability">
                                    {{$t('availability.'+collectionModal.data.availability.type)}} - {{collectionModal.data.availability.date | getIntDate}} - {{collectionModal.data.availability.time | getIntTime}} - {{collectionModal.data.availability.min}} {{$t('general.min')}} </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:middle"><strong>{{$t('general.amount')}}</strong></td>
                            <td style="vertical-align:middle">
                                <h2 style="margin:0">{{collectionModal.data.amount}} {{collectionModal.data.currency | getCurrency}}</h2>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr class="my-3">
                <credit-card-form :payment="collectionModal.data" @start-payed="doPayment" />
            </div>
        </modal>
    </div>
</template>

<script>
/*
check('amount', 'Amount is required').not().isEmpty(),
check('currency', 'Currency is required').not().isEmpty(),
check('patient', 'Patient is required').not().isEmpty(),
check('availability', 'Availability is required').not().isEmpty(),
*/

import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { handleError } from '@/utils/func';
import CreditCardForm from '../../../components/CreditCardForm.vue';

import { getCookie } from '@/utils/cookie';

let self;

export default {
    components: { CreditCardForm },
    computed: {
        ...mapState(['userLang']),
        ...mapGetters({ payments: 'getPaymentLinks', total: 'getPaymentLinkTotal', pages: 'getPaymentLinkPages', userType: 'getUserType', patientList: 'getPatients', appointments: 'getAppointments', branches: 'getBranchList', 'userInfo' : 'getProfile'})
    },
    data() {
        return {
            loading: false,
            appointmentLoading: false,
            masks: {
                input: 'DD.MM.YYYY'
            },
            currencyList: ['try', 'usd', 'eur', 'gbp'],
            processModal: {
                status: false,
                index: false
            },
            paymentModal: {
                loading: true,
                status: false,
                index: false,
                data: false,
                formData: {
                    amount: 0,
                    currency: 'try',
                    patient: '',
                    availability: '',
                    maxAmount: 0,
                    period_validity: 30
                }
            },
            collectionModal: {
                loading: false,
                status: false,
                data: {},
                formData: {},
                startPayment:false,
                transactionSrc:''
            },
            range: {
                start: moment()
                    .clone()
                    .startOf('month')
                    .toDate(),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .toDate()
            },
            filter: {
                text: '',
                earnStatus: '',
                appointmentStatus: '',
                paymentStatus: '',
                paymentMethod: '',
                currency: 'try',
                doctor: '',
                type: '',
                start: moment()
                    .clone()
                    .startOf('month')
                    .format('YYYYMMDD'),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .format('YYYYMMDD')
            },
            pagination: {
                limit: 10,
                page: 1
            },
            messageReceive: false,
            paymentCompleted: false,
            paymentError: false,
        };
    },
    methods: {
        receiveMessage(data) {
            let self = this;
            if (!self.messageReceive) {
                self.messageReceive = true;
                if (data.payment == 'completed') {
                    let paymentData = _.cloneDeep(self.collectionModal.data);
                    self.closeCollectionModal();

                    self.loading = true;
                    setTimeout(() => {
                        let paymentForm = {
                            "payedAmount": paymentData.amount,
                            "paymentMethod": "creditcard",
                            "paymentStatus": "completed",
                            "bank": 'paytr',
                            "availabilityId": paymentData.availability._id,
                            "uuid": self.userInfo.uuid,
                            "rules": true,
                            "paymentLink": paymentData.uuid
                        }

                        self.$store
                            .dispatch('completeAppointment', paymentForm)
                            .then(status => {
                                if (status) {
                                    self.paymentCompleted = true;
                                    self.$notify({ type: 'success', message: self.$i18n.t('success.payment_completed') });
                                } else {
                                    self.$notify({ type: 'danger', message: self.$i18n.t('error.some_error') });
                                }

                                self.loading = false;
                            })
                            .catch(err => {
                                handleError(self, err);
                            });
                    }, 1000);
                } else {
                    self.paymentError = true;
                    self.$notify({ type: 'danger', message: self.$i18n.t('error.some_error') });
                }
            }
        },
        async doPayment(data){
            let self = this;
            let clinic = getCookie('user-clinic');

            const payData = {
                "availability": self.collectionModal.data.availability._id,
                "paymentLink": true,
                "clinic": clinic ?? 'e-doktor',
                "patient": this.userInfo.uuid,
                "cardInfo": {
                    "owner": data.name,
                    "number": data.cardNumber.replaceAll(' ', ''),
                    "month": data.expireMonth,
                    "year": data.expireYear,
                    "cvv": data.cvv.toString()
                },
                "successUrl": "paydone",
                "failedUrl": "payfailed",
                "userLang": this.userInfo.lang
            }

            this.$store.dispatch('generateToken', payData).then((obj) => {
                let paymentUrl = obj.paymentUrl?obj.paymentUrl:'';
                self.collectionModal.transactionSrc = paymentUrl+'?id='+obj.transaction;
                self.collectionModal.startPayment = true;
            })
            .catch((errorCode) => {
                self.$notify({ type: 'success', message: self.$i18n.t('error.'+errorCode) });
            });
        },
        handleDelete(uuid){
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch('DELETE_PAYMENT_LINK', {uuid})
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        updatePaymentLink(){
            let formData = _.cloneDeep(self.paymentModal.formData);
            self.paymentModal.loading = true;
            self.$store
                .dispatch('PAYMENT_LINK_UPDATE', {uuid: formData.uuid, payment: formData})
                .then(resp => {
                    self.closePaymentModal();
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.paymentModal.loading = false;
                    handleError(self, err);
                });
        },
        addPaymentLink(){
            let formData = _.cloneDeep(self.paymentModal.formData);
            self.paymentModal.loading = true;
            self.$store
                .dispatch('ADD_PAYMENT_LINK', formData)
                .then(resp => {
                    self.closePaymentModal();
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.paymentModal.loading = false;
                    handleError(self, err);
                });
        },
        getRemaining(item){
            let amount = item.availability[0].price;
            let payed = _.sumBy(_.filter(item.payments, {paymentStatus: 'completed'}), 'amount');
            let canceled = _.sumBy(_.filter(item.payments, {paymentStatus: 'canceled'}), 'amount');
            return amount - (payed - canceled);
        },
        changeAppointmentSelect(item) {
            //console.log(item)
            //self.paymentModal.formData.availability = item.availability[0]._id;
            self.paymentModal.formData.appointment = item.uuid;
            self.paymentModal.formData.currency = item.availability[0].currency;
            self.paymentModal.formData.maxAmount = self.getRemaining(item);
        },
        changePatientSelect() {
            let patient = _.find(self.patientList, {uuid: self.paymentModal.data.patient});
            self.paymentModal.formData = {
                amount: '',
                currency: 'try',
                patient: patient._id,
                availability: '',
                maxAmount: 0,
                period_validity: 30
            }
            
            self.getAppointment(self.paymentModal.data.patient);
        },
        getAppointment(patient) {
            return new Promise((resolve, reject) => {
                self.appointmentLoading = true;
                self.$store
                    .dispatch('APPOINTMENT_REQUEST', { pagination: { limit: 10000 }, filter: {patient, noBalance: true, paymentStatus: 'waiting', start: parseInt(moment().startOf('year').format('YYYYMMDD')), end: parseInt(moment().add(5, 'years').format('YYYYMMDD'))} })
                    .then((resp) => {
                        resolve(true);
                    })
                    .catch(err => {
                        handleError(self, err);
                    })
                    .finally(() => {
                        self.appointmentLoading = false;
                    });
            });
        },
        doProcess(process) {
            self.processModal.status = false;
            let row = self.payments[self.processModal.index];
            switch (process) {
                case 'approved':
                    self.approvedRefund(row.uuid);
                    break;
                case 'transfer':
                    self.transferBalance(row);
                    break;
                default:
                    break;
            }
        },
        closeCollectionModal(){
            self.collectionModal = {
                loading: false,
                status: false,
                data: {},
                formData: {},
                startPayment:false,
                transactionSrc:''
            }
        },
        openCollectionModal(payment){
            self.collectionModal = {
                loading: false,
                status: true,
                data: _.cloneDeep(payment),
                formData: {},
                startPayment:false,
                transactionSrc:''
            }

            self.messageReceive= false;
            self.paymentCompleted= false;
            self.paymentError= false;
        },
        openPaymentModal(uuid){
            self.$store.commit('APPOINTMENT_CLEAR');
            let index = uuid ? _.findIndex(self.payments, {uuid}) : false;
            const data = uuid ? _.find(self.payments, {uuid}) : {};

            self.paymentModal = {
                loading: true,
                status: true,
                index,
                data,
                formData: {
                    amount: '',
                    currency: 'try',
                    patient: '',
                    availability: '',
                    maxAmount: 0,
                    period_validity: 30
                }
            }

            if(index !== false && data.patient !== undefined){
                //self.appointments.availability[0]._id
                self.getAppointment(data.patient.uuid).then(async () => {
                    self.paymentModal = {
                        loading: false,
                        status: true,
                        index,
                        data,
                        formData: {
                            uuid: data.uuid,
                            amount: parseFloat(data.amount),
                            currency: data.currency,
                            patient: data.patient._id,
                            availability: data.availability._id,
                            maxAmount: 0,
                            period_validity: data.period_validity
                        }
                    }

                    await Promise.all(
                        _.forEach(self.appointments, (item) => {
                            if(item.availability[0]._id == data.availability._id){
                                self.paymentModal.formData.availability = item.availability[0]._id;
                                self.paymentModal.formData.currency = item.availability[0].currency;
                                self.paymentModal.formData.maxAmount = self.getRemaining(item);
                            }
                        })
                    )
                });
                return;
            }

            self.paymentModal.loading = false;
        },
        closePaymentModal(){
            self.paymentModal = {
                loading: true,
                status: false,
                index: false,
                data: false,
                formData: {
                    amount: '',
                    currency: '',
                    patient: '',
                    availability: '',
                    maxAmount: 0,
                    period_validity: 30
                }
            }
            self.$store.commit('APPOINTMENT_CLEAR');
        },
        openProcessModal(index) {
            self.processModal.index = index;
            self.processModal.status = true;
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        },
        changePage(val) {
            self.pagination.page = val;
            self.getData();
        },
        setDates() {
            _.set(self.filter, 'start', moment(self.range.start).format('YYYYMMDD'));
            _.set(self.filter, 'end', moment(self.range.end).format('YYYYMMDD'));

            self.getData();
        },
        getPatientList(){
            if(self.userType!='admin') return;

            self.$store
                .dispatch('PATIENT_REQUEST', { pagination: { limit: 100000 }, filter: {status:true}, fields: '_id name phone uuid' })
                .catch(err => {
                    handleError(self, err);
                })
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch('PAYMENT_LINK_REQUEST', { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        approvedEarn(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.earn_approve_confirm'),
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { earn: 'completed' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        approvedRefund(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.payment_refund_confirm'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { payment_status: 'refunded' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        transferBalance(row) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.payment_transfer_confirm'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_TRANSFER, { amount: row.amount, currency:row.currency, desc: '', type: 'receivable', paymentMethod: 'transfer', payment: row.uuid, patient: row.patient.uuid })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        checkValidityTime(createdAt, period_validity){
            let date = moment(createdAt).add(period_validity, 'minutes');
            return moment().isBefore(date);
        }
    },
    filters:{
        getRemaining(item){
            let amount = item.availability[0].price;
            let payed = _.sumBy(_.filter(item.payments, {paymentStatus: 'completed'}), 'amount');
            let canceled = _.sumBy(_.filter(item.payments, {paymentStatus: 'canceled'}), 'amount');
            return amount - (payed - canceled);
        },
        getValidityDate(item){
            let date = moment(item.createdAt).add(item.period_validity, 'minutes');
            return date.format('DD.MM.YYYY HH:mm');
        },
        getBranchName(branches) {
            let id = _.first(branches);
            let lang = self.$i18n.locale;
            let branch = _.first(
                _.filter(self.branches, item => {
                    return item._id == id;
                })
            );

            return branch && branch.name[lang] ? branch.name[lang] : self.$i18n.t('error.no_translate');
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener(
                'message',
                event => {
                    if (event.data.paytr !== undefined) {
                        this.receiveMessage(event.data);
                    }
                },
                false
            );
        });
    },
    beforeDestroy() {
        this.$nextTick(() => {
            window.removeEventListener('message', ()=>{}, false);
        });
    },
    created() {
        self = this;
        self.getData();
        self.getPatientList();
    }
};
</script>
