<template>
    <div class="card-form">
        <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <div class="card-form__inner">
                    <div class="card-input">
                        <validation-provider :rules="'required|vCreditCard'" :name="$t('general.creditcard_number')" v-slot="{ errors, valid, invalid, validated }">
                            <label for="cardNumber" class="form-control-label">{{$t('general.creditcard_number')}}</label>
                            <input v-mask="cardMask" @accept="onAcceptCardType" v-model="cardNumber" autofocus id="cardNumber" class="card-input__input" autocomplete="off" />
                            <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                {{ errors[0] }}
                            </div>
                        </validation-provider>
                    </div>
                    <div class="card-input">
                        <validation-provider :rules="'required'" :name="$t('general.creditcard_owner')" v-slot="{ errors, valid, invalid, validated }">
                            <label for="cardName" class="form-control-label">{{$t('general.creditcard_owner')}}</label>
                            <input id="cardName" class="card-input__input" v-model="name" autocomplete="off" />
                            <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                {{ errors[0] }}
                            </div>
                        </validation-provider>
                    </div>
                    <div class="card-form__group">
                        <b-row>
                            <b-col cols="11" md="7">
                                <b-row>
                                    <b-col cols="12">
                                        <label for="cardMonth" class="form-control-label">{{$t('general.creditcard_expire')}}</label>
                                    </b-col>
                                    <b-col cols="6" class="pr-0">
                                        <validation-provider :rules="'required'" :name="$t('general.month_short')" v-slot="{ errors, valid, invalid, validated }">
                                            <select class="card-input__input -select" id="cardMonth" v-model="expireMonth">
                                                <option value disabled selected>{{$t('general.month_short')}}</option>
                                                <option v-for="n in 12" :key="n" :value="n" v-if="expireYear==currentYear&&currentMonth<n || expireYear!=currentYear">{{ 10 > n ? "0" + n : n }}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                                {{ errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="6" class="p-0">
                                        <validation-provider :rules="'required'" :name="$t('general.year_short')" v-slot="{ errors, valid, invalid, validated }">
                                            <select class="card-input__input -select" id="cardYear" v-model="expireYear">
                                                <option value disabled selected>{{$t('general.year_short')}}</option>
                                                <option v-for="(n, $index) in 12" :value="$index + currentYear" :key="n">{{ $index + currentYear }}</option>
                                            </select>
                                            <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                                {{ errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="5">
                                <div class="card-input">
                                    <validation-provider :rules="'required|min:3'" :name="$t('general.cvv')" v-slot="{ errors, valid, invalid, validated }">
                                        <label for="cardCvv" class="form-control-label mt-1">{{$t('general.cvv')}}</label>
                                        <input type="text" class="card-input__input" id="cardCvv" v-model="cvv" autocomplete="off" v-mask="'####'" @accept="onAcceptCvv" />
                                        <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                            {{ errors[0] }}
                                        </div>
                                    </validation-provider>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="text-center">
                        <b-button variant="success" type="submit" :disabled="invalid">{{ $t("general.pay") }}</b-button>
                    </div>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import Vue from 'vue';
import { validateCreditCard } from '@/utils/func';
import { extend } from 'vee-validate';

extend('vCreditCard', value => {
  return !validateCreditCard(value)? 'Geçerli bir kredi kartı numarası yazınız' : true;
});

export default {
    data() {
        return {
            cardNumber: '',
            cardMask: '#### #### #### ####',
            cardMasks: {
                mastercard: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
                visa: /^4\d{0,15}/,
            },
            expireMonth: '',
            expireYear: '',
            name: '',
            cvv: '',
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth(),
            showBack: false,
            symbolImage: 'mastercard'
        };
    },
    props:{
        payment: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        onSubmit(){
            this.$refs.formValidator.validate().then(success => {
                if (success) {
                    this.$emit('start-payed', {
                        cardNumber: this.cardNumber,
                        expireMonth: this.expireMonth,
                        expireYear: this.expireYear,
                        name: this.name,
                        cvv: this.cvv
                    });
                }
            });
        },
        onAcceptCardType(e) {
            const maskRef = e.detail;
            const type = maskRef.masked.currentMask.cardtype;

            if (type !== 'Unknown') {
                this.symbolImage = type;
            }

            this.cardNumber = maskRef.value;
        },
        onAcceptCvv(e) {
            const maskRef = e.detail;
            this.cvv = maskRef.value;
        }
    },
    watch: {
        cardNumber(value) {
            const number = value.replace(/\D/g, "");

            if(this.cardMasks.mastercard.test(number)) {
                this.cardMask = '#### #### #### ####';
            } else if(this.cardMasks.visa.test(number)) {
                this.cardMask = '#### #### #### ####';
            } else {
                this.cardMask = '#### #### #### ####';
            }
        }
    }
};
</script>

<style>
.card-container {
    margin: 30px auto 50px auto;
}
.card-form {
    max-width: 570px;
    margin: auto;
    width: 100%;
}

.card-form__row {
    display: flex;
    align-items: flex-start;
}
.card-form__col {
    flex: auto;
    margin-right: 15px;
}
.card-form__col:last-child {
    margin-right: 0;
}
.card-form__col.-cvv {
    max-width: 150px;
}
.card-form__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.card-form__group .card-input__input {
    flex: 1;
    margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
    margin-right: 0;
}
.card-form__button {
    width: 100%;
    height: 55px;
    background: #38a294;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
}
.card-form__button:hover {
    background: #318f83;
}
.card-input {
    margin-bottom: 20px;
}
.card-input__label {
    margin-bottom: 5px;
    color: #1a3b5d;
    width: 100%;
    display: block;
    text-align: left;
}
.card-input__input {
    width: 100%;
    height: 38px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
}
.card-input__input:hover,
.card-input__input:focus {
    border-color: #38a294;
}
.card-input__input:focus {
    box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
#cardYear,
#cardMonth {
    margin-top: 4px;
}
</style>
